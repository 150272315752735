// src/components/ContactMe.js
import React from 'react';

const ContactMe = () => (
  <section id="contact">
    <h2>Contact Me</h2>
    <a href="/hossain_zarif_resume.pdf" download="Zarif_Hossain_Resume.pdf" className="resume-download-link">
      Download My Resume
    </a>
    <h1><p>Feel free to reach out to me using the following contact information!</p>
    <p>Email: zarifhossain23@gmail.com</p>
    <p>Phone: +1(951)-289-8003</p></h1>
  </section>
);

export default ContactMe;
