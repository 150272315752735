// src/components/Header.js
import React from 'react';

const Header = ({ isDarkMode, toggleTheme }) => (
  <header className={isDarkMode ? 'header-dark' : 'header-light'}>
    <div className="header-container">
      <h1>Zarif Hossain's Portfolio</h1>
      <nav>
      <button className="theme-toggle" onClick={toggleTheme} aria-label="Toggle theme">
          {isDarkMode ? (
            <img src="/img/110805_sun_icon.svg" alt="Sun icon for light mode" style={{ width: '24px' }} />
          ) : (
            <img src="/img/110827_moon_icon.svg" alt="Moon icon for dark mode" style={{ width: '24px' }} />
          )}
        </button>
        <a href="#about">About Me</a>
        <a href="#contact">Contact Me</a>
        <a href="https://www.linkedin.com/in/zarif-hossain" target="_blank" rel="noopener noreferrer">
          <img src="/img/linkedin.svg" alt="LinkedIn" style={{ width: '24px', marginRight: '8px' }} />
        </a>
        <a href="https://github.com/chiefzarif/" target="_blank" rel="noopener noreferrer">
          <img src="/img/github.svg" alt="GitHub" style={{ width: '24px', marginRight: '8px' }} />
        </a>
        
      </nav>
    </div>
  </header>
);

export default Header;
