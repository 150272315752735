// src/components/AboutMe.js
import React from 'react';

const AboutMe = () => (
  <section id="about">
    <h2>About Me</h2>
    <div className="about-grid">
      <div className="about-image">
        <img src="/img/IMG_2476.jpeg" alt="Zarif Hossain" style={{ width: '30%', borderRadius: '8px' }} />
      </div>
      <div className="about-text">
        <center><p>
          <h1>Hello! I am Zarif Hossain, a fourth-year undergraduate 
          Computer Science and Engineering student at the University of California, Irvine. My coursework and projects
          have given me an assortment of skills related to the field, such as Python, JavaScript, C/C++, and many more.  
          I am interested in all fields of software, particularly machine learning, web development, and avionics.
          I am also experienced in mobile development, as I have developed those skills through my software engineering internship at Advanced Bionics, working on the 
          Research & Technology team to work on a mobile application for use by cochlear implant patients. I am currently seeking a full-time position as a software engineer for summer 2025!
          </h1>
        </p></center>
        <h2>Interests</h2>
        <h3>Basketball, football, soccer, weightlifting, video games, and music!</h3>
      </div>
    </div>
  </section>
);

export default AboutMe;
